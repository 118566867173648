// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


// include highlight.js styles
@import '~highlight.js/styles/atom-one-dark.css';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$light-test: (
  50: #e3f2ff,
  100: #badfff,
  200: #8ccbff,
  300: #57b6ff,
  400: #28a6ff,
  500: #0096ff,
  600: #0287ff,
  700: #1773f5,
  800: #1f61e2,
  900: #263fc3,
  A100: #80D8FF,
  A200: #40C4FF,
  A400: #00B0FF,
  A700: #0091EA,
  contrast: (
    50: rgba(black, 0.01),
    100: rgba(black, 0.01),
    200: rgba(black, 0.01),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.01),
    A200: rgba(black, 0.01),
    A400: white,
    A700: white,
  )
);

$link-insider-primary: mat.define-palette($light-test);
$link-insider-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);


// The warn palette is optional (defaults to red).
$link-insider-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$link-insider-theme: mat.define-light-theme((
  color: (
    primary: $link-insider-primary,
    accent: $link-insider-accent,
    warn: $link-insider-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($link-insider-theme);

//@font-face {
//  font-family: 'DM Sans';
//  src: url("./assets/fonts/DMSans-Bold.eot");
//  src: local("DM Sans Bold"), local("DMSans-Bold"), url("../fonts/DMSans-Bold.eot#iefix") format("embedded-opentype"), url("../fonts/DMSans-Bold.woff2") format("woff2"), url("../fonts/DMSans-Bold.woff") format("woff"), url("../fonts/DMSans-Bold.ttf") format("truetype");
//  font-weight: bold;
//  font-style: normal
//}
//
//@font-face {
//  font-family: 'DM Sans';
//  src: url("../fonts/DMSans-Medium.eot");
//  src: local("DM Sans Medium"), local("DMSans-Medium"), url("../fonts/DMSans-Medium.eot#iefix") format("embedded-opentype"), url("../fonts/DMSans-Medium.woff2") format("woff2"), url("../fonts/DMSans-Medium.woff") format("woff"), url("../fonts/DMSans-Medium.ttf") format("truetype");
//  font-weight: 500;
//  font-style: normal
//}
//
//@font-face {
//  font-family: 'DM Sans';
//  src: url("../fonts/DMSans-Regular.eot");
//  src: local("DM Sans Regular"), local("DMSans-Regular"), url("../fonts/DMSans-Regular.eot#iefix") format("embedded-opentype"), url("../fonts/DMSans-Regular.woff2") format("woff2"), url("../fonts/DMSans-Regular.woff") format("woff"), url("../fonts/DMSans-Regular.ttf") format("truetype");
//  font-weight: normal;
//  font-style: normal
//}

$color-default: #5643D8;
$hover-color: #7363D8;

*, *::before, *::after {
  box-sizing: border-box
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block
}

body {
  margin: 0;
  line-height: 1.65;
  color: #526484;
  text-align: left;
  background-color: #EAEFF2;
  font-size: 14px;
  font-weight: 400;
  font-family: Poppins,Helvetica,"sans-serif";
  -webkit-font-smoothing: antialiased;
}

//body[dir="rtl"] {
//  text-align: right;
//  font-family: 'Noto Nastaliq Urdu', sans-serif;
//}
//
//body[dir="ltr"] {
//  text-align: left;
//}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem
}

p {
  margin-top: 0;
  margin-bottom: 1rem
}

p:last-child {
  margin-bottom: 0;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0
}

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: #559bfb;
  text-decoration: none;
  background-color: transparent
}

a:hover {
  color: #1775f5;
  text-decoration: underline
}

a:not([href]) {
  color: inherit;
  text-decoration: none
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none
}

table {
  border-collapse: collapse
}

textarea {
  overflow: auto;
  resize: vertical
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: .5rem;
  font-family: "Poppins", sans-serif!important;
  font-weight: 500;
  line-height: 1.1;
  color: #526484
}

h1, .h1 {
  font-size: 2rem
}

h2, .h2 {
  font-size: 1.75rem
}

h3, .h3 {
  font-size: 1.5rem
}

h4, .h4 {
  font-size: 1.25rem
}

h5, .h5 {
  font-size: 1.15rem
}

h6, .h6 {
  font-size: 1rem
}

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, .h1:last-child, .h2:last-child, .h3:last-child, .h4:last-child, .h5:last-child, .h6:last-child, p:last-child {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  h1, .h1 {
    font-size: 3.75rem;
    font-weight: 400;
    letter-spacing: -0.04em
  }

  h2, .h2 {
    font-size: 2rem;
    letter-spacing: -0.03em
  }

  h3, .h3 {
    font-size: 1.75rem;
    letter-spacing: -0.03em
  }

  h4, .h4 {
    font-size: 1.5rem
  }

  h5, .h5 {
    font-size: 1.25rem
  }

  h6, .h6 {
    font-size: 1.05rem
  }
}

.g-gs {
  margin: -14px !important
}

.g-gs > li, .g-gs > div {
  padding: 14px !important
}

.g-gs > li, .g-gs > div.pr-0 {
  padding-right: 0 !important;
}

.g-gs > li, .g-gs > div.pl-0 {
  padding-left: 0 !important;
}

.g-2 {
  margin: -.375rem !important
}

.g-2 > li, .g-2 > div {
  padding: .375rem !important
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -14px;
  margin-left: -14px
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-mb-1, .col-mb-2, .col-mb-3, .col-mb-4, .col-mb-5, .col-mb-6, .col-mb-7, .col-mb-8, .col-mb-9, .col-mb-10, .col-mb-11, .col-mb-12, .col-mb, .col-mb-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 14px;
  padding-left: 14px
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%
}

@media (min-width: 420px) {
  .col-mb {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .col-mb-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-mb-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%
  }

  .col-mb-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%
  }

  .col-mb-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-mb-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%
  }

  .col-mb-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%
  }

  .col-mb-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-mb-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%
  }

  .col-mb-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%
  }

  .col-mb-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-mb-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%
  }

  .col-mb-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%
  }

  .col-mb-12 {
    flex: 0 0 100%;
    max-width: 100%
  }
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%
  }

  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%
  }

  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%
  }

  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%
  }

  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%
  }

  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%
  }

  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%
  }

  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%
  }

  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%
  }

  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%
  }

  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%
  }

  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%
  }

  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%
  }

  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%
  }

  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%
  }

  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%
  }

  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%
  }
}

@media (min-width: 1540px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%
  }

  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%
  }

  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%
  }

  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%
  }

  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%
  }
}

.nk-body {
  outline: none
}

.bg-lighter {
  background-color: #eaeff2 !important
}

a.bg-lighter:hover,
a.bg-lighter:focus,
button.bg-lighter:hover,
button.bg-lighter:focus {
  background-color: #d3d7e9 !important
}

.nk-sidebar {
  //position: absolute;
  background: #f5f6fa;
  height: 100%;
  min-height: calc(100vh - 62px);
  top: 0;
  left: 0;
  transform: translateX(-100%);
  border-right: 1px solid #e5e9f2;
  z-index: 999;
  width: 290px;
  transition: transform 450ms ease, width 450ms ease;
  box-shadow: 0px 1px 3px 0px rgba(54, 74, 99, 0.05)
}

.nk-sidebar + .nk-wrap {
  transition: padding 450ms ease
}

.nk-sidebar.is-light {
  background: #fff;
  border-right-color: #ebeef2
}

.nk-sidebar-element {
  overflow: hidden;
  width: 100%;
  max-height: 100%
}

.nk-sidebar-fixed {
  position: fixed;
  max-height: 100vh
}

.nk-sidebar-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 24px;
  min-width: 100%;
  width: 290px;
  height: 62px
}

.nk-sidebar-brand {
  position: relative;
  flex-shrink: 0
}

.logo-img {
  max-height: 36px
}

.logo-img-lg {
  max-height: 60px
}

.logo-img-sm {
  max-height: 28px
}

.logo-img-icon {
  max-height: 33px
}

.logo-img:not(:first-child) {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%)
}

.is-light .nk-sidebar-footer {
  background: #fff
}

.nk-sidebar-logo {
  transition: opacity .3s ease
}

.nk-sidebar-logo .logo-img:not(:first-child) {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%)
}

.nk-sidebar-footer {
  background-color: #f5f6fa;
  margin-top: auto;
  padding: 1.75rem 24px 1.75rem
}

.is-light .nk-sidebar-footer {
  background: #fff
}

.nk-sidebar-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 65px)
}

.nk-sidebar-content .nk-sidebar-menu {
  padding-top: 1.5rem;
  padding-bottom: 3rem
}

.nk-sidebar-content .nk-sidebar-menu[data-simplebar] {
  height: 100%
}

.nk-sidebar-content[data-simplebar] > div {
  width: 100%
}

@media (min-width: 1200px) {
  .nk-sidebar-content .nk-sidebar-menu {
    padding-top: 1rem;
    padding-bottom: 2.5rem
  }

  .nk-sidebar {
    overflow: hidden;
    transform: translateX(0);
    width: 290px
  }

  .nk-sidebar + .nk-wrap, .nk-sidebar-overlay + .nk-wrap {
    padding-left: 290px
  }

  .nk-sidebar + .nk-wrap > .nk-header-fixed, .nk-sidebar-overlay + .nk-wrap > .nk-header-fixed {
    left: 290px
  }

  .nk-sidebar.is-compact:not(:hover) {
    width: 74px
  }

  .nk-sidebar.is-compact:not(:hover) .logo-img {
    opacity: 0;
    transition: opacity .2s linear
  }

  .nk-sidebar.is-compact:not(:hover) .logo-img-small {
    opacity: 1;
    left: -3px
  }

  .nk-sidebar.is-compact:not(:hover) .user-card {
    position: relative;
    left: -5px
  }

  .nk-sidebar.is-compact:not(:hover) .user-info {
    opacity: 0
  }

  .nk-sidebar.is-compact:not(:hover) .nk-sidebar-profile .dropdown-menu {
    opacity: 0
  }

  .nk-sidebar.is-compact + .nk-wrap {
    padding-left: 74px
  }

  .nk-sidebar.is-compact + .nk-wrap > .nk-header-fixed {
    left: 74px
  }

  .nk-sidebar-overlay {
    display: none
  }
}

@media (min-width: 1200px) {
  .nk-sidebar {
    transform: translateX(0)
  }
}

.nk-sidebar .nk-menu > li .nk-menu-sub .nk-menu-link {
  padding-left: 48px
}

.nk-sidebar .nk-menu > li .nk-menu-sub .nk-menu-sub {
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding-bottom: 0;
  margin-left: 48px;
  border-left: 1px solid #dbdfea
}

.nk-sidebar .nk-menu > li .nk-menu-sub .nk-menu-sub .nk-menu-link {
  padding-left: 1rem
}

.nk-menu li a, .nk-menu li .nk-menu-link {
  vertical-align: middle;
  display: flex;
  position: relative;
  align-items: center;
  transition: color .3s, background-color .3s
}

.nk-menu-item {
  padding: 0 12px;
  margin-top: 1px;
}

.nk-menu-sub .nk-menu-item {
  padding: 1px 0
}

.nk-menu-item.has-sub {
  position: relative
}

.nk-menu > .nk-menu-item.menu-without-icon > .nk-menu-sub .nk-menu-link {
  padding-left: 0
}

.is-theme .nk-menu-link:hover .nk-menu-icon, .is-theme .nk-menu-item.active > .nk-menu-link .nk-menu-icon, .is-theme .nk-menu-item.current-menu > .nk-menu-link .nk-menu-icon {
  color: #fff
}

.nk-menu-link {
  padding: .525rem 20px .525rem 12px;
  color: #6e82a5;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: .01em;
  text-transform: none;
  line-height: 1.25rem;
  border-radius: 6px;
}

button.nk-menu-link {
  border: none;
  text-align: left;
  width: 100%;
  background-color: transparent;
}

.nk-menu-link:hover, .active > .nk-menu-link {
  color: #559bfb;
  background: #fff;
  text-decoration: none;
}

.is-light .nk-menu-link {
  color: #526484
}

.is-light .nk-menu-link:hover, .is-light .active > .nk-menu-link {
  color: #559bfb;
  background: #ebeef2;
  [class^="icon-"], [class*=" icon-"] {
    background: -webkit-linear-gradient(51deg, #1EBDC2 0%, #5447D8 100%);
    //background: linear-gradient(51deg, #1EBDC2 0%, #5447D8 100%);
    background: linear-gradient(51deg, #1EBDC2 0%, #5447D8 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
  }
}



.nk-menu-sub .nk-menu-link {
  padding: .375rem 40px .375rem 36px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: normal;
  text-transform: none;
  line-height: 1.25rem;
  color: #6e82a5
}

.nk-menu-link.is-disable {
  cursor: default
}

.nk-menu-link.is-disable:hover, .active > .nk-menu-link.is-disable {
  color: #6e82a5
}

.nk-menu-link.is-disable:hover .count, .active > .nk-menu-link.is-disable .count {
  color: #6e82a5
}

.is-light .nk-menu-link {
  color: #526484
}

.nk-header.is-light:not([class*=bg-]) {
  background: #fff
}

.nk-menu-link.is-disable:hover .nk-menu-icon {
  color: #8094ae
}

.nk-menu-text {
  flex-grow: 1;
  display: inline-block
}

.nk-menu-sub {
  padding-bottom: .25rem
}

.nk-menu-toggle + .nk-menu-sub {
  display: none
}

.active > .nk-menu-sub {
  display: block
}

.nk-menu-sub .nk-menu-link {
  padding-left: 48px
}

.nk-menu-sub .nk-menu-link:hover {
  color: #1775f5
}

.nk-menu-sub .nk-menu-link:hover, .active > .nk-menu-sub .nk-menu-link {
  background-color: transparent
}

.nk-menu-sub .nk-menu-sub {
  margin-left: 48px;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding-bottom: 0
}

.nk-menu-sub .nk-menu-sub .nk-menu-link {
  padding-left: 1rem
}

.nk-menu-sub .active > .nk-menu-link {
  color: #1775f5
}

.is-theme .nk-menu-sub .active > .nk-menu-link {
  color: #fff
}

.nk-menu-sub .nk-menu-icon {
  width: 24px;
  margin-top: -1px
}

.nk-menu-sub .nk-menu-icon .icon {
  margin-top: -1px;
  font-size: 1.2em
}

.nk-menu-toggle {
  position: relative
}

.nk-menu-toggle:after {
  position: absolute;
  font-family: "Nioicon";
  top: 50%;
  content: "";
  right: 1.25rem;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #8094ae;
  transition: transform .3s, color .1s, opacity .3s ease .3s
}

.has-sub.active > .nk-menu-toggle:after {
  transform: translateY(-50%) rotate(90deg)
}

.nk-menu-toggle {
  position: relative
}

.nk-menu-toggle:after {
  position: absolute;
  font-family: "Nioicon";
  top: 50%;
  content: "";
  right: 1.25rem;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #8094ae;
  transition: transform .3s, color .1s, opacity .3s ease .3s
}

.logo-link {
  position: relative;
  display: inline-block;
  align-items: center
}

.logo-dark {
  opacity: 1
}

/*
.tc-light .logo-dark, .is-dark .logo-dark, .is-theme .logo-dark {
    opacity: 0
}*/
.nk-header-mobile .logo-dark {
  opacity: 1
}

.nk-wrap {
  display: flex;
  flex-direction: column;
  //min-height: 100vh;
}

.nk-sidebar + .nk-wrap {
  transition: padding 450ms ease
}

.nk-sidebar + .nk-wrap > .nk-header-fixed {
  transition: left 450ms ease
}

.nk-header {
  background: #f5f6fa;
  border-bottom: 1px solid #e5e9f2;
  padding: 0 6px;
  box-shadow: 0px 1px 3px 0px rgba(54, 74, 99, 0.05)
}

.nk-header.is-light:not([class*=bg-]) {
  background: #fff
}

.nk-header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 800;
  min-width: 320px
}

.nk-header-fixed + .nk-content {
  margin-top: 65px;
  position: relative
}

.nk-header-wrap {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 -.25rem;
  height: 62px;
}

.nk-header-wrap > * {
  padding: 0 .25rem
}

.nk-header-right {
  align-items: center;
  justify-content: flex-end
}

.nk-header-center {
  justify-content: center
}

.nk-header-brand {
  flex-shrink: 0;
  padding-top: 14px;
  padding-bottom: 14px
}

.nk-header .dropdown-menu .lead-text {
  color: #526484
}

.nk-header .dropdown-menu .sub-text, .nk-header .dropdown-menu .overline-title, .nk-header .dropdown-menu .overline-title-alt {
  color: #8094ae
}

.nk-header-search {
  display: none
}

.nk-header-search .form-control {
  background-color: transparent;
}

@media (min-width: 576px) {
  .nk-header {
    padding: 0 22px
  }

  .nk-header-search {
    display: flex;
    align-items: center;
    flex-grow: 1
  }

  .nk-quick-nav {
    margin: 0 -10px
  }

  .nk-quick-nav > li {
    padding: 0 10px
  }
}

.container-fluid {
  width: 100%;
  padding-right: 14px;
  padding-left: 14px;
  margin-right: auto;
  margin-left: auto
}

.nk-block-tools {
  display: flex;
  align-items: center
}

.nk-block-tools > * {
  display: inline-flex
}

.form-control-wrap, .form-control-group {
  position: relative;
}

.h-100px {
  height: 100px !important
}

.h-100 {
  height: 100% !important
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important
}

.mt-3, .my-3 {
  margin-top: 1rem !important
}

.ml-3, .mx-3 {
  margin-left: 1rem !important
}

.mr-n1, .mx-n1 {
  margin-right: -.375rem !important
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important
}

.pb-3, .py-3 {
  padding-bottom: 1rem !important
}

.pr-0, .px-0 {
  padding-right: 0 !important
}

.pl-0, .px-0 {
  padding-left: 0 !important
}

.pl-4, .px-4 {
  padding-left: 1.5rem !important
}

.pt-3, .py-3 {
  padding-top: 1rem !important
}

.pt-4, .py-4 {
  padding-top: 1.5rem !important
}

.gy-4 {
  margin-top: -.75rem !important;
  margin-bottom: -.75rem !important
}

.gy-4 > li, .gy-4 > div {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important
}

.dropdown-toggle {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0
}

.dropdown-toggle {
  white-space: nowrap
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
  margin-left: 0
}

.dropdown-toggle:not([class*="indicator"]):after {
  display: none
}

.dropdown {
  display: inline-flex
}

.dropdown-menu {
  overflow: hidden;
  border: 1px solid #e5e9f2;
  box-shadow: 0 3px 12px 1px rgba(58, 34, 114, 0.15)
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  text-align: left;
  min-width: 180px;
  padding: 0 0;
  margin: .125rem 0 0;
  font-size: .8125rem;
  color: #526484;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5e9f2;
  border-radius: 4px
}

.dropdown-menu-left {
  right: auto;
  left: 0
}

.dropdown-menu-right {
  right: 0;
  left: auto
}

.dropdown-menu-md {
  min-width: 300px;
  max-width: 300px
}

.dropdown-menu.show {
  display: block;
}

@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-inner {
  padding: 0 1.75rem
}

.dropdown-inner + .dropdown-inner {
  border-top: 1px solid #e5e9f2
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto
  }
}

.dropdown-menu.show {
  display: block
}

.link-list {
  padding: .75rem 0;
}

.link-list a {
  display: flex;
  align-items: center;
  color: #526484;
  font-size: 13px;
  line-height: 1.4rem;
  font-weight: 500;
  padding: .575rem 0;
  position: relative;
}

.link-list-opt.no-bdr {
  padding: .5rem 0
}

.link-list-opt .divider {
  margin: .5rem 0
}

.link-list-opt a {
  display: flex;
  align-items: center;
  padding: .625rem 1.125rem;
  font-size: 12px;
  font-weight: 500;
  color: #526484;
  transition: all .4s;
  line-height: 1.3rem;
  position: relative
}

.link-list-opt a:hover {
  color: #1775f5;
  background: #f5f6fa
}

.link-list-opt a .icon {
  font-size: 1.125rem;
  width: 1.75rem;
  opacity: .8
}

.link-list-opt li.disabled > a, .link-list-opt a.disabled {
  pointer-events: none;
  color: #8094ae;
  opacity: 0.7
}

.nk-header-tools {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-top: 14px;
  padding-bottom: 14px
}

.nk-quick-nav {
  display: flex;
  align-items: center;
  margin: 0 -6px
}

.nk-quick-nav > li {
  padding: 0 6px
}

.nk-quick-nav > li.user-dropdown > a {
  padding: 0 2px
}

@media (max-width: 359px) {
  .nk-quick-nav {
    margin: 0 -3px
  }

  .nk-quick-nav > li {
    padding: 0 3px
  }
}

@media (min-width: 576px) {
  .nk-header {
    padding: 0 22px
  }

  .nk-header-search {
    display: flex;
    align-items: center;
    flex-grow: 1
  }
}

.nk-quick-nav .dropdown-menu {
  margin-top: 11px;
  margin-right: -6px
}

@media (max-width: 420px) {
  .dropdown-menu-xl,
  .dropdown-menu-lg,
  .dropdown-menu-md {
    min-width: calc(100vw - 40px);
    max-width: calc(100vw - 40px)
  }

  .nk-quick-nav .dropdown-menu-xl,
  .nk-quick-nav .dropdown-menu-lg,
  .nk-quick-nav .dropdown-menu-md {
    margin-right: 0;
    margin-left: 16px
  }

  .nk-quick-nav li:last-child .dropdown-menu-xl,
  .nk-quick-nav li:last-child .dropdown-menu-lg,
  .nk-quick-nav li:last-child .dropdown-menu-md {
    margin-left: 0;
    margin-right: 3px
  }
}

.user-toggle {
  display: flex;
  align-items: center;
  padding: 0 1px 1px
}

.user-avatar, [class^="user-avatar"]:not([class*="-group"]) {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: $color-default;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.06em;
  flex-shrink: 0;
  position: relative
}

.user-avatar-sm, .user-avatar.sm {
  height: 32px;
  width: 32px;
  font-size: 12px;
}

.user-avatar + .user-info, [class^="user-avatar"]:not([class*="-group"]) + .user-info {
  margin-left: 1rem;
}

.user-name {
  color: #526484;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500
}

.user-card {
  display: flex;
  align-items: center
}

.user-card-wrap {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem
}

.user-card .user-info {
  color: #8094ae
}

.user-info .lead-text, .user-info .sub-text {
  display: flex;
  align-items: center
}

.lead-text {
  font-size: 0.875rem;
  font-weight: 500;
  color: #526484;
  display: block
}

.lead-text + .sub-text {
  font-size: 12px
}

.lead-text span {
  color: #526484;
  font-weight: 400
}

@media (min-width: 992px) {
  .lead {
    font-size: 1.09375rem;
  }
}

.nk-content {
  padding: 24px 4px
}

@media (min-width: 576px) {
  .nk-content {
    padding: 32px 22px
  }

  .nk-block-content + .nk-block-head-sm {
    padding-top: 2.5rem
  }

}

.nk-header-fixed + .nk-content {
  margin-top: 65px;
  position: relative
}

.nk-content-body {
  flex-grow: 1
}

.nk-content-body > .nk-block-head:first-child {
  padding-bottom: 1.75rem
}

@media (min-width: 768px) {
  .nk-content-body > .nk-block-head:first-child {
    padding-bottom: 2.5rem
  }

  .nk-content-body > .nk-block-head-sm:first-child {
    padding-bottom: 1.75rem
  }
}

.nk-block-head {
  position: relative;
  padding-bottom: 1.25rem
}

.nk-block-head:only-child {
  padding-bottom: 0
}

.nk-block-head h2:not(:last-child) {
  margin-bottom: 1rem
}

.nk-block-head-sm {
  padding-bottom: 1rem
}

.nk-block-content + .nk-block-head-sm {
  padding-top: 1.5rem
}

.nk-block-between {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.nk-block-between > .title:first-child {
  margin-bottom: 0
}

.page-title {
  font-family: "Poppins", sans-serif !important;
  font-size: 1.5rem !important;
  font-weight: 500 !important
}

@media (min-width: 992px) {
  .page-title {
    font-size: 1.2rem !important
  }
}

.nk-block + .nk-block, .nk-block + .nk-block-head {
  padding-top: 28px
}

.nk-block + .nk-block-lg, .nk-block + .nk-block-head-lg {
  padding-top: 2.5rem
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 4px
}

.card {
  box-shadow: 0px 1px 3px 0px rgb(54 74 99 / 5%);
}

.card + .card {
  margin-top: 28px;
}

.card-full {
  height: 100%;
}

.overflow-hidden {
  overflow: hidden !important;
}

.card-inner {
  padding: 1.25rem
}

.card-inner .title-col {
  color: #1775F5;
}

@media (min-width: 576px) {
  .card-inner {
    padding: 1.5rem;
  }
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.card-title-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative
}

.card-title {
  margin-bottom: .75rem
}

.card-title-group .card-title {
  margin-bottom: 0
}

.card-title-group:only-child, .card-title-group .card-search {
  margin-top: -.25rem;
  margin-bottom: -.25rem
}

.card-title-sm .title {
  font-size: 14px;
}

.nk-ecwg5-ck {
  height: 140px;
}

.nk-ecwg5 .data-group {
  margin-bottom: 30px
}

.nk-ecwg6-ck {
  width: 100px;
  height: 40px;
  margin-left: 20px
}

.nk-ecwg .amount {
  font-size: 2rem;
  font-weight: 500;
  color: #526484
}

.nk-ecwg .amount-col {
  color: #1775f5;
}

.nk-ecwg .info {
  font-size: .875rem;
}

.nk-ecwg .data {
  margin-top: 0.5rem;
}

.nk-ecwg .data:not(:last-child) {
  margin-bottom: 2rem
}

.nk-ecwg .data-group {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.nk-ecwg .data-group .data {
  margin: 0;
  padding-right: 20px
}

.change {
  line-height: 1;
}

.change.up {
  color: #1ee0ac !important;
}

.change.down {
  color: #e85347 !important
}

.shrink-0 {
  flex-shrink: 0;
}

.card-tools {
  display: flex;
}

.nav {
  margin: -.5rem -1rem
}

.nav .nav-link {
  display: inline-flex;
  align-items: center
}

.nav-tabs {
  font-family: "Poppins", sans-serif;
  margin: 0;
  font-size: 0.8125rem;
  border-bottom: 1px solid #dbdfea
}

.nav-tabs-s2 {
  border-bottom: none;
  justify-content: center
}

.nav-tabs + .tab-content {
  margin-top: 1.25rem
}

.nav-tabs + .nk-block {
  padding-top: 1.5rem
}

@media (min-width: 768px) {
  .nav-tabs + .nk-block {
    padding-top: 2.5rem
  }
}

.nav-tabs .nav-item {
  padding-right: 1.25rem
}

.nav-tabs .nav-item:last-child {
  padding-right: 0
}

.nav-tabs .nav-item.active .nav-link {
  color: #1775f5
}

.nav-tabs .nav-item.active .nav-link:after {
  opacity: 1
}

.nav-tabs .nav-link {
  padding: 1rem 0;
  font-weight: 500;
  color: #526484;
  position: relative;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center
}

.nav-tabs .nav-link:hover {
  color: #526484
}

.nav-tabs .nav-link:focus {
  color: #1775f5
}

.nav-tabs .nav-link:before, .nav-tabs .nav-link:after {
  position: absolute;
  content: ''
}

.nav-tabs .nav-link:after {
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background: #1775f5;
  opacity: 0
}

.nav-tabs .nav-link.active {
  color: #1775f5;
  border: none;
  background-color: transparent
}

.nav-tabs .nav-link.active:after {
  opacity: 1
}

@media (min-width: 768px) {
  .nav-tabs .nav-item {
    padding-right: 1.5rem
  }
}

@media (min-width: 992px) {
  .nav-tabs .nav-item {
    padding-right: 1.75rem
  }

  .nav-tabs .nav-link {
    font-size: 0.875rem
  }
}

@media (min-width: 1200px) {
  .nav-tabs .nav-item {
    padding-right: 2.25rem
  }
}

.nav-switch-s2 {
  display: inline-flex;
  border-radius: 4px;
  border: none
}

.nav-switch-s2 .nav-link {
  padding: .25rem .7rem;
  line-height: 20px;
  border-radius: 0;
  position: static;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #8094ae;
  border: 1px solid #dbdfea
}

.nav-switch-s2 .nav-link:hover, .nav-switch-s2 .nav-link:focus {
  color: #526484;
  background: #f5f6fa;
  border: 1px solid #dbdfea
}

.nav-switch-s2 .nav-link.active {
  color: #526484;
  border: 1px solid #dbdfea;
  background: #f5f6fa;
  box-shadow: inset 0 0 4px -1px rgba(128, 148, 174, 0.25)
}

.nav-switch-s2 .nav-link:after {
  display: none
}

.nav-switch-s2 .nav-item {
  padding: 0;
  margin: 0
}

.nav-switch-s2 .nav-item:last-child {
  margin-right: 0
}

.nav-switch-s2 .nav-item:last-child .nav-link {
  border-radius: 0 4px 4px 0
}

.nav-switch-s2 .nav-item:first-child {
  margin-left: 0
}

.nav-switch-s2 .nav-item:first-child .nav-link {
  border-radius: 4px 0 0 4px
}

.nav-switch-s2 .nav-item:not(:first-child) {
  margin-left: -1px
}

.bg-white {
  background-color: #fff !important
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #526484;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dbdfea;
}

.table th {
  line-height: 1.1;
  text-align: left;
}

.table th, .table td {
  padding: .5rem;
  vertical-align: top;
  border-top: 1px solid #dbdfea;
}

.table .thead-light th {
  color: #526484;
  background-color: #f5f6fa;
  border-color: #dbdfea
}

.table thead tr:last-child th {
  border-bottom: 1px solid #dbdfea;
}

.table td:first-child, .table th:first-child {
  padding-left: 1.25rem;
}

.border {
  border: 1px solid #dbdfea !important
}

.border-top {
  border-top: 1px solid #dbdfea !important
}

.border-right {
  border-right: 1px solid #dbdfea !important
}

.border-bottom {
  border-bottom: 1px solid #dbdfea !important
}

.bdr-b-2 {
  border-bottom: 2px solid #dbdfea;
}

.nk-tb-list {
  display: table;
  width: 100%;
  font-size: 13px;
  color: #8094ae;
}

.nk-tb-item {
  transition: background-color .3s, box-shadow .3s;
  display: table-row;
}

.nk-tb-item:not(.nk-tb-head):hover,
.nk-tb-item:not(.nk-tb-head).seleted {
  background: #f8f9fc;
  box-shadow: 0 2px 15px -4px rgb(85 155 251 / 40%);
}

.nk-tb-item:not(:last-child) .nk-tb-col {
  border-bottom: 1px solid rgba(219, 223, 234, 0.9)
}

@media (min-width: 576px) {
  .nk-tb-item .nk-tb-col:first-child {
    padding-left: 1.5rem
  }

  .nk-tb-item .nk-tb-col:last-child {
    padding-right: 1.5rem
  }
}

.is-loose .nk-tb-item:not(.nk-tb-head) .nk-tb-col {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem
}

.nk-tb-head .nk-tb-col {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #8094ae;
  font-size: .9em;
  border-bottom: 1px solid rgba(219, 223, 234, 0.9)
}

.nk-tb-col {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  padding: 1rem .5rem;
}

.nk-tb-col:first-child {
  padding-left: 1.25rem;
}

.progress {
  display: flex;
  height: .5rem;
  overflow: hidden;
  line-height: 0;
  font-size: .75rem;
  background-color: #f5f6fa;
  border-radius: 2px;
}

.progress-alt {
  display: flex;
  align-items: center;
  overflow: visible;
}

.progress-md {
  height: .375rem;
}

.bg-transparent {
  background-color: transparent !important;
}

.progress-alt .progress-bar {
  border-radius: 2px;
}

.progress-bar {
  height: 100%;
  line-height: 1;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1775f5;
  transition: width 0.6s ease;
}

.progress-amount {
  font-size: 12px;
  padding-left: .5rem;
}

.form-group:last-child {
  margin-bottom: 0;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group {
  position: relative;
  margin-bottom: 1.25rem;
}

.form-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #344357;
  margin-bottom: .5rem;
}

label {
  cursor: pointer;
}

.form-control-wrap, .form-control-group {
  position: relative;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}

.form-control-select-multiple .custom-select {
  padding-top: 0.375rem;
  padding-bottom: 0.875rem;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.125rem + 2px);
  padding: .4375rem 2rem .4375rem 1rem;
  font-size: .8125rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #3c4d62;
  vertical-align: middle;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  appearance: none;
}

.custom-control-label::before, .custom-file-label, .custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
}

.custom-select {
  background: #fff;
}

.form-control-select-multiple .custom-select option {
  border-radius: 3px;
  padding: 5px .5rem;
  margin: 2px -.5rem;
  cursor: pointer;
  color: #526484;
}

.custom-select option {
  border-radius: 3px;
  padding: 5px .5rem;
  margin: 2px -.5rem;
  cursor: pointer;
  color: #526484;
}

.two-col-data {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.two-col-data .table {
  border: 1px solid #dbdfea;
}

.nk-block-des {
  color: #526484;
}

.select2-container {
  box-sizing: border-box;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2 {
  width: 100% !important;
  display: inline-block
}

.select2-container {
  box-sizing: border-box;
  margin: 0;
  position: relative;
  vertical-align: middle
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: calc(2.125rem + 2px);
  user-select: none;
  -webkit-user-select: none
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important
}


.select2-container--default .select2-selection--single {
  height: calc(2.125rem + 2px);
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  background-color: #fff;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  box-shadow: none;
  transition: all 0.3s
}

.select2-container--default .select2-selection--single:focus {
  box-shadow: 0 0 0 3px rgba(133, 79, 255, 0.1);
  border-color: #1775f5;
  outline: none
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #526484;
  line-height: 1.25rem;
  padding: .4375rem calc(2.125rem + 2px) .4375rem 1rem
}

.select2-container--default .select2-selection--single .select2-selection__rendered:focus {
  outline: none
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #8094ae
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: calc(2.125rem + 2px);
  position: absolute;
  top: 0;
  right: 0;
  width: calc(2.125rem + 2px);
  display: flex;
  align-items: center;
  justify-content: center
}

.select2-container--default .select2-selection--multiple {
  background-color: #fff;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  cursor: text
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: .125rem 0.1875rem;
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none
}

@media (min-width: 767.98px) {
  .two-col-data .table.th-130 th {
    min-width: 130px;
  }
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.form-wrapper {
  width: 600px;
  max-width: 100%;
  margin: {
    left: auto;
    right: auto;
  }
  padding: 3%;
  background: #fff;
}

.img-responsive {
  max-width: 100%;
  display: block;
}

.input-group-prepend img, .icon-img {
  max-width: 15px;
}

.btn-round {
  border-radius: 10em;
}

.app-download-item {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    margin-bottom: 10px;
  }
}

.card-body .amount {
  font-size: 2rem;
  font-weight: 500;
  color: #526484
}

.card-body .amount-col {
  color: #1775f5;
}

.bg-gradient-main {
  background-image: linear-gradient(90deg, #5643D8, #559BFB);
}

.text-default {
  color: $color-default;
}

.btn.btn-default {
  background-color: $color-default;
  color: #fff;
  padding: .375rem 2rem;

  &-small {
    //padding: 0.3rem .375rem 0;
    background-color: $color-default;
    color: #fff!important;
  }
  &-outline {
    border: 1px solid $color-default;
    color: $color-default;
    background: #fff;
  }
  &:hover, &:active {
    color: #fff;
    background-color: $hover-color;
  }
}

.form-control-wide {
  width: 350px;
  max-width: 100%;
}

.color-primary {
  color: #2277E0;
}

.color-default {
  color: $color-default;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-prepend, .input-group-append {
  display: flex;
}

.mr-1, .mx-1 {
  margin-right: 0.25rem !important;
}

.mr-2, .mx-2 {
  margin-right: 0.5rem !important;
}

.card-body {
  padding: 1.25rem;
}

.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.badge-warning {
  color: #ffc107;
  background-color: rgb(255 193 7 / 15%);
  border: 1px solid #ffc107;
}
.alert-warning{
    border-radius: 4px;
    border: 1px solid #fbe59f;
    font-size: 14px;
    align-items: center;
}
.gap-8{
  gap:8px;
}
.gap-16{
  gap:16px;
}

.badge-pill {
  padding: 5px 10px;
  border-radius: 10rem;
  min-width: 80px;
}

.badge-success {
  color: #5cb85c;
  background-color: rgb(92 184 92 / 15%);
  border: 1px solid #5cb85c;
}

.badge-dark {
  color: #ffffff;
  background-color: #485172;
  border: 1px solid #485172;
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col, .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}


.form-group label {
  font-weight: 500;
  color: #526484;
  margin-bottom: 3px;
  font-size: 16px;
}

.text-right {
  text-align: right;
}

.form-control {
  border-color: #D9D9D9;
}


body {
  font-family: Poppins, sans-serif;
}

.mat-icon.inline-icon {
  vertical-align: bottom;
}

dashboard-progress-bar {
  display: block;
}

.centered-image {
  margin: 0 auto;
}

::selection {
  background: #4975dc;
  color: #fff;
}
//.btn-primary {
//  color: #fff;
//  background-color: #523F9E!important;
//  border-color: #523F9E!important;
//
//  &:hover {
//    color: #fff;
//    background-color: #413083 !important;
//    border-color: #413083 !important;
//    box-shadow: 0 0.125rem 0.25rem rgb(113 84 170 / 64%) !important;
//  }
//}
.mat-paginator,
.mat-paginator-page-size
.mat-select-trigger {
  color: #526484;
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgb(234 239 242);
}
.mat-select-value,
.mat-select-arrow,
.mat-option{
  color: #526484;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #526484;
}

.mat-cell, .mat-footer-cell {
  color: #526484;
}
.mt-5, .my-5 {
  margin-top: 2rem!important;
}
th.mat-header-cell {
  text-align: left;
  color: #526484;
  text-transform: uppercase;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #526484;
  border-color: #526484;
}
.form-control{
  color: #526484;
}
.btn-outline-secondary {
  color: #526484;
  border-color: #526484;

  &:hover {
    color: #fff;
    background-color: #526484;
    border-color: #526484;
  }
}
.blnc{
  font-weight: 500;
}
.badge-default {
  background-color: rgb(13 110 253 / 15%);
  color: #0D6EFD;
  border: 1px solid #0D6EFD;
}

.badge-subscription {
  background-color: #ffab00;
  color: #000;
}

.mat-custom-select.mat-form-field-appearance-outline {
  width: 100%;
  .mat-form-field-infix {
    padding: 0.5em 0 1em 0;
  }
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $color-default;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: $color-default;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $color-default;
}

.info-message {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

body .mat-paginator-container {
  justify-content: center;
}

.card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  min-height: 70px;
  padding: 0 2.25rem;
  background-color: transparent;
  border-bottom: 1px solid #EFF2F5;
}

.card .card-header .card-title, .card .card-header .card-title .card-label {
  font-weight: 500;
  font-size: 1.275rem;
  color: #181c32;
}
.m-0 {
  margin: 0 !important;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem;
}

.mb-7 {
  margin-bottom: 1.75rem!important;
}

.text-dark {
  color: #181c32!important;
}

.fw-bolder {
  font-weight: 600!important;
}

.fs-6 {
  font-size: 1.075rem!important;
}

.border-dashed {
  border-style: dashed!important;
  border-color: #e4e6ef;
}

.bg-light-warning {
  background-color: #fff8dd!important;
}
.rounded {
  border-radius: 0.475rem!important;
}
.p-6 {
  padding: 1.5rem!important;
}

.card .card-body {
  padding: 2rem 2.25rem;
}

.p-9 {
  padding: 2.25rem!important;
}

.align-self-center {
  align-self: center!important;
}

.fw-bold {
  font-weight: bold;
}


element.style {
}
.card .card-header .card-title, .card .card-header .card-title .card-label {
  font-weight: 500;
  font-size: 1.275rem;
  color: #181c32;
}

.card .card-header .card-title {
  display: flex;
  align-items: center;
  margin: 0.5rem;
  margin-left: 0;
}

.nav-line-tabs .nav-item .nav-link.active, .nav-line-tabs .nav-item.show .nav-link, .nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #009EF7;
  transition: color .2s ease,background-color .2s ease;
}

.codeblock {
  position: relative;
  .copy-icon {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
/*radio button*/
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color: #1EB9C0!important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #1EB9C0!important;
}
.mat-radio-container:hover .mat-radio-persistent-ripple {
  opacity: .04;
}
.mat-radio-label-content{
  font-family: "Poppins", sans-serif;
}
/*end radio button*/

/*calendar*/
.mat-calendar-body-comparison-identical.mat-calendar-body-selected, .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #1eb9c0;
}
.mat-calendar-body-comparison-identical, .mat-calendar-body-in-comparison-range::before {
  background: rgb(30 185 192 / 20%);
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  background: rgb(30 185 192 / 20%);
}
.mat-calendar-body-selected {
  background-color: #1EB9C0;
}
/*end calendar*/

.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #1EB9C0;
  border: 2px solid;
}

.two-lines {
  position: relative;
  margin: 5px 0;
  display: block;
  &::before, &::after {
    background-color: #ccc;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 30%;
  }
  &:before {
    right: 0.5em;
    margin-left: -50%;
  }

  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
}

.usage-detail-row.success {
  border-left: 3px solid green;
}

.usage-detail-row.error {
  border-left: 3px solid red;
}

.opacity-0 {
  opacity: 0 !important;
}

.red {
  color: red;
}

.form-check {
  min-height: 1.5rem;
  //padding-left: 2.25rem;
  margin-bottom: 0.125rem;
}

.mb-8 {
  margin-bottom: 2rem!important;
}

.mt-8 {
  margin-top: 2rem!important;
}

.mt-4 {
  margin-top: 1rem!important;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.full-min-height {
  min-height: calc(100vh - 120px);
}

.proxy-list-wrapper .mat-list-base .mat-list-item, .proxy-list-wrapper .mat-list-base .mat-list-option {
  height: auto;
  font-size: 12px;
}

.proxy-list-wrapper {
  .mat-list-base {
    padding-top: 0;
  }
  .mat-list-base .mat-list-item .mat-list-item-content, .mat-list-base .mat-list-option .mat-list-item-content {
    padding: 0;
  }
}


.max-w-900 {
  max-width: 900px;
}


.p-0375 {
  padding: 0.375rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.height-100 {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}


@media only screen and (max-width: 769px) {
  .card .card-body {
    padding: 1rem 1.25rem;
  }
}

@media only screen and (min-width: 768px) {
  .ml-desktop-2, .mx-desktop-2 {
    margin-left: 0.5rem !important;
  }
}

@media only screen and (max-width: 993px) {
  .mt-mobile-3 {
    margin-top: 1rem !important;
  }
  .codeblock {
    display: flex;
    .copy-icon-wrapper {
      height: 44.7px;
      border: 1px solid #202020;
      padding: 9px 5px;
      background: #fff;
    }
    .copy-icon {
      position: static;
    }
  }
}


.btn-color-danger {
  color: red;
}


.small-card {
  //background: #F8F9FC;
  border: 1px solid rgba(219,223,234,.9);
  border-radius: 4px;
  &-header {
    padding: 1rem 1.125rem 0;
  }
  &-title {
    font-size: 18px;
  }
  &-body {
    padding: 1rem 1.125rem;
  }
}

body .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.7em 0;
}

.left-border {
  border-left: 2px solid #333;
  padding-left: 10px;
}


.mat-form-field-infix {
  border-top: 0.44375em solid transparent;
  mat-label {
    font-weight: 400;
    color: #6c757d;
    font-family: "Poppins", sans-serif;
  }
}

body .mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin-top: 0;
}

.small-card .col-form-label-sm {
  margin-bottom: 5px;
}

.font-f{
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #526484;
}
.mat-dialog-container {
  color: #526484;
}
.mat-option,
.mat-select-value-text {
  font-family: "Poppins", sans-serif;
}
.dx-button-text,
.btn{
  font-family: "Poppins", sans-serif;
  text-transform: inherit;
}

.btn.dx-button-success:hover,
.btn.dx-button-success:active,
.dx-button.dx-button-success.dx-state-active,
.dx-button.dx-button-success.dx-state-focused,
.btn.btn-default-small:hover{
  color: #fff;
  background-color: #7363D8;
}
.dx-button-mode-text.dx-button-success,
.dx-button.dx-button-success.dx-state-focused,
.btn-default-small{
  color: #5643D8;
}

.dx-button-mode-text.dx-button-success.dx-state-focused,
.dx-button-mode-text.dx-button-success.dx-state-hover,
.dx-button-mode-text.dx-button-success .dx-inkripple-wave{
  background-color: rgb(86 67 216 / 8%);
}
.dx-button-mode-text.dx-button-success .dx-inkripple-wave{
  color: #7363D8;
}

.dx-button-mode-text.dx-button-success.dx-state-active{
  background-color: rgb(86 67 216 / 36%);
  color: #ffffff;
}


.h-100vh {
  min-height: 100vh;
  min-height: 100dvh;
}

.bgi-size-cover {
  background-size: cover;
}

.flex-column-fluid {
  flex: 1 0 auto;
}

.h-60px {
  height: 60px!important;
}

.py-7 {
  padding-top: 1.75rem!important;
  padding-bottom: 1.75rem!important;
}

.px-5 {
  padding-right: 1.25rem!important;
  padding-left: 1.25rem!important;
}

.text-wrap-balance {
  text-wrap: balance;
}

.fs-2qx {
  font-size: calc(1.35rem + 1.2vw)!important;
}
//.bf {
//  background-color: #212121;
//  color: #fff;
//  text-align: center;
//  position: fixed;
//  top: 62px;
//  z-index: 1;
//  width: calc(100% - 290px);
//  display: block;
//  padding: 5px;
//}

.bf {
  background-color: #333333;
  color: #fff;
  text-align: center;
  display: block;
  padding: 5px;
  margin-top: -23px;
  margin-bottom: 20px;
}
@media (min-width: 1200px) {
  .fs-2qx {
    font-size: 2.25rem !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row-fluid {
    flex: 1 auto;
    min-width: 0;
  }
  .w-lg-50 {
    width: 50%!important;
  }
  .h-lg-75px {
    height: 75px!important;
  }
  .py-lg-15 {
    padding-top: 3.75rem!important;
    padding-bottom: 3.75rem!important;
  }
  .w-lg-500px {
    width: 500px!important;
  }
}

@media (min-width: 768px) {
  .px-md-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
}

.btn-with-icon {
  .mat-icon {
    transform: translateY(3px);
    margin-right: 2px;
  }
}

.bf-label {
  color: #fff;
  background-color: #333333;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
}

.pl-8px {
  padding-left: 8px;
}
.btn-200{
  max-width: 200px;
  min-width: 190px;
  padding: 0.375rem 1rem!important;
}
.btn-dwnl {
  font-size: 14px;

  .mat-icon{
    font-size: 16px;
  }
}


.missing-amount-label {
  margin-top: 5px;
}

.missing-amount-button {
  text-decoration: underline;
  color: #5643D8;
  font-weight: bold;
}


.old-price {
  display: block;
  color: var(--danger);
  font-size: 12px;
  &--inline {
    display: inline;
    line-height: 23px;
  }
}


.trust-block-mobile{
  display: none;
}

@media screen and (max-width: 768px){
  .trust-block-desktop{
    display: none;
  }
  .trust-block-mobile{
    display: block;
  }
}


/*Dark menu*/
.dark-th .nk-sidebar {
  background: #1f2438;
  border-right-color: #1f2438;
}

.dark-th .nk-menu-link:hover, .dark-th .active > .nk-menu-link {
  background: hsl(214deg 95% 66% / 10%);
}

.dark-th .nk-menu-link {
  color: #ffffff;
}

.dark-th .nk-submenu {
  margin-left: 10px;
  margin-top: 4px;
}
/*End Dark menu*/


.certificate img {
  max-width: 70px;
}
