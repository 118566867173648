.mat-form-field-wrapper {
  padding: 0;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5em 0;
}

.mat-form-field-label-wrapper {
  top: -18px;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.259375em) scale(0.75);
}

.mat-h6, .mat-typography h6 {

}
