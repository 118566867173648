@font-face {
  font-family: 'dataimpulse';
  src:  url('../fonts/dataimpulse.eot?jilef3');
  src:  url('../fonts/dataimpulse.eot?jilef3#iefix') format('embedded-opentype'),
    url('../fonts/dataimpulse.ttf?jilef3') format('truetype'),
    url('../fonts/dataimpulse.woff?jilef3') format('woff'),
    url('../fonts/dataimpulse.svg?jilef3#dataimpulse') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dataimpulse' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-idea:before {
  content: "\e909";
}
.icon-language:before {
  content: "\e905";
}
.icon-logout:before {
  content: "\e906";
}
.icon-payments:before {
  content: "\e907";
}
.icon-profile:before {
  content: "\e908";
}
.icon-affiliate:before {
  content: "\e904";
}
.icon-nomobile:before {
  content: "\e903";
}
.icon-mobile:before {
  content: "\e900";
}
.icon-residential:before {
  content: "\e901";
}
.icon-datacenter:before {
  content: "\e902";
}
.icon-plans:before {
  content: "\e921";
}
.icon-docs:before {
  content: "\e922";
}
.icon-dashboard:before {
  content: "\e923";
}
.icon-products:before {
  content: "\e924";
}
.icon-proxy-locations:before {
  content: "\e925";
}


